import React from 'react';
import './ProgrammeHeader.css';

const ProgrammeHeader = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = isFirstLoad ? "" : props.loadingMessage;
  const programmeCode = props.programmeCode;

  // render
  return (<>
    <div className="header">
      <div className="app-wrapper header-wrapper">
        <div data-testid="app-title" className="programme-title">Programme {programmeCode}</div>
        <div className="loading-message">{loadingMessage}</div>
      </div>
    </div>
  </>);
}

export default ProgrammeHeader;