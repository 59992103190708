import React from 'react';
import './DefaultView.css';

import ProgrammeHeader from '../components/ProgrammeHeader';
import ProgrammeBody from '../components/ProgrammeBody';

const DefaultView = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = props.loadingMessage;
  const theProgramme = props.theProgramme;
  const programmeCode = props.programmeCode;

  // render
  return (<>
    <div className="DefaultView">
      <ProgrammeHeader programmeCode={programmeCode} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} />
      <ProgrammeBody theProgramme={theProgramme} isFirstLoad={isFirstLoad} />
    </div>
  </>);
}

export default DefaultView;