import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderTimespanToAgoPhrase } from './library/DateTimeFuncs.js';
import { useInterval } from './hooks';
import EnvironmentBar from './components/EnvironmentBar';
import DefaultView from './views/DefaultView';
import { fetchProgramme } from './library/BffFuncs';
import './App.css';

function App() {
  // define state and defaults
  const [dateTimeDependency, setDateTimeDependency] = useState(new Date());
  const [dateTimeLastLoaded, setDateTimeLastLoaded] = useState(new Date());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [theProgramme, setTheProgramme] = useState(undefined);

  // use the uniqueRef if it is part of the route params
  let { programmeCode } = useParams();

  // fetch parameters from the URI
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // if we don't have a uniqueRef as a route param then try get it from the query params
  if (!programmeCode) {
    programmeCode = urlParams.get("programmeCode");
  }

  // define programme loader function (running in intervals)
  useInterval(() => {
    setDateTimeDependency(new Date())
  }, 1000); // polls for updates every 1 second

  useEffect(() => {
    async function fetchTheProgramme() {
      // quick exit if something is loading
      if (isLoading) {
        return;
      }

      // quick exit if the class has been fetched just recently, so we won't fetch right now
      let timeSpan = new Date() - dateTimeLastLoaded;
      if (!isFirstLoad && timeSpan < 59.5 * 1000) { // nearly 1 minute
        setLoadingMessage(renderTimespanToAgoPhrase(dateTimeLastLoaded, "Info refreshed "));
        return;
      }

      // fetch the programme
      setIsLoading(true);
      setLoadingMessage("Refreshing ...");
      const loadedProgramme = await fetchProgramme(programmeCode);
      setIsFirstLoad(false);
      setTheProgramme(loadedProgramme);
      setDateTimeLastLoaded(new Date()); // remember when the programme was loaded last (for status rendering)

      // remove loading message
      setIsLoading(false);
    }
    fetchTheProgramme();
  }, [dateTimeDependency, dateTimeLastLoaded, isFirstLoad, isLoading, programmeCode]);

  return (
    <div className="app">
      <EnvironmentBar />
      <DefaultView programmeCode={programmeCode} theProgramme={theProgramme} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} />
    </div>
  );
}

export default App;
