import React from 'react';
import './ProgrammeBody.css';
import ProgrammeBodyElement from './ProgrammeBodyElement';

// render the programme body
const ProgrammeBody = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const theProgramme = props.theProgramme;

  // determine statuses and other info
  const unavailableTerm = "Unavailable";
  const unavailableTermClassCode = "No class code available"; 
  const isProgrammeDefined = theProgramme !== undefined;
  const name = isProgrammeDefined ? !!theProgramme.name ? theProgramme.name : unavailableTerm : undefined;
  const subjectName = isProgrammeDefined ? !!theProgramme.subjectName ? theProgramme.subjectName : unavailableTerm : undefined;
  const classViewerUrl = process.env.REACT_APP_APP_CLASSVIEWER_BASE || 'BUGGER:REACT_APP_APP_CLASSVIEWER_BASE';
  const classViewerLink = classViewerUrl + '?uniqueRef=';
  
  const classesElements = isProgrammeDefined
    ? theProgramme.classes.map(
      (classItem, index) => <ProgrammeBodyElement
        key={classItem.uniqueRef} // TODO TG: shouldn't that be the id?
        title={!index ? "Classes" : ""}
        text={<a href={classViewerLink + classItem.uniqueRef}>{classItem.uniqueRef}</a>}
        subText={classItem.classCode === unavailableTerm ? unavailableTermClassCode : classItem.classCode} />
    )
    : <></>;

  // render either that we're loading data (if loaded for the first time), or the programme, or that the programme does not exist
  return isProgrammeDefined
    ? <>
      <ProgrammeBodyElement title="Name" text={name} />
      <ProgrammeBodyElement title="Subject" text={subjectName} />
      {classesElements}
    </>
    : isFirstLoad
      ? // this is the very first load
      <ProgrammeBodyElement title="Loading ..." className="notification" />
      : // this is a subsequent load
      <ProgrammeBodyElement title="Programme does not exist." className="error" />
}

export default ProgrammeBody;
